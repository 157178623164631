import React, { Component, createRef } from 'react'
import Button from '@material-ui/core/Button';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Typography, Box, TextField, InputAdornment, Slide, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import EmpTable from './PRPEmpTable';
import CloseIcon from '@material-ui/icons/Close';
import Service from '../../config/networkutils';
import { weekStartDates } from '../../utils/betweenDates';
import {
    prp_roles_api,
    prp_template_api,
    prp_roles_api_bulk_update,
    prptandPrprDeleteByPRPtid,
    conditionalPRPTandPRPRcreate,
} from '../../config/apiList';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import { withSnackbar } from 'notistack';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import XLSX from "xlsx";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Modal from '../../shared/Modal/Modal';
import Dropzone from '../../shared/Dropzone/Dropzone';
import { getCookie } from '../../utils/CookieHelper';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getActiveRoles, getRoleGroups, getSubSector } from '../../config/PromisesHandler';
import { getLookupObj, getRolesLookupObj } from '../../utils/reuseFunctions';
// import { generalSortOrder } from '../../config/abstractedVariables';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcom from '@material-ui/icons/RemoveCircle';
import SearchIcon from '@material-ui/icons/Search';
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UserAllocation from '../UserAllocation/UserAllocation';
import Autocomplete from '@material-ui/lab/Autocomplete';
import WorkIcon from '@material-ui/icons/Work';
import { getPrimaryColor } from '../Helper/CommonThings';
import AllocateSchedules from '../UserAllocation/AllocateSchedules';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("ptd_ts_token");

const useStyle = (theme) => ({
    customGridStyle: {
        margin: 0,
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px'
    },
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        width: "calc(100% - 20px)"
    },
    rows: {
        backgroundColor: "#EDFAFF",
    },
    leftSticky: {
        zIndex: 999,
        left: 0,
        top: 0,
        background: "#F8F8F8",
        textAlign: "center",
        position: 'sticky'
    }
})

// const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]

class PRPTemplate extends Component {
    constructor(props) {
        super(props);
        this.empTableRef = React.createRef()
        this.state = {
            columns: [],
            data: [],
            copyData: [],
            zoom: 0.9,
            project_name: '',
            project_no: '',
            contract_start_date: '',
            contract_end_date: '',
            weekDateRange: [],
            start_date: '',
            subTableCol: [],
            subTabledata: [],
            showDialog: false,
            selectedRole: {},
            selectedEmployee: {},
            modelOpen: false,
            roles: [],
            rolesObj: {},
            rolesToPost: "",
            isLoading: true,
            allEmp: [],
            allEmpObj: {},
            reviewModal: false,
            dataToUpload: [],
            dropZoneModal: false,
            reviewTableCol: [],
            reviewTableData: [],
            newRoles: [],
            disciplineArr: [],
            disciplineObj: {},
            discipline: '',
            owner_delivery_unit: {},
            owner_region: {},
            sector: {},
            sortByRoles: [
                // { id: 117 },
                // { id: 3 },
                // { id: 2 },
            ],
            totalCountType: false,
            rowDelete: false,
            deleteRowData: [],
            deleteRowIndex: [],
            deleteDisabled: true,
            deleteDisabled1: false,
            addDisabled: false,
            multipleSelect: false,
            assignDisabled: true,
            workType: [],
            selectedEmployees: [],
            customQtyValues: [],
            singleCustomQtyValues: [],
            singleShowDialog: false,
            type: null,
            addQtyDisbaled: false,
            deleteQtyID: [],
            allocationDialogSize: 'sm',
            allocationDialog: false,
            role: null,
            addResource: false,
            scrollTop: false,
            openedRows: [],
            roleGroups: [],
            selectedRoleGroup: null,
            tabValue: 0,
            addRoleDisabled: true,
            addEmployeeDisabled: true
        }
        this.topRef = createRef()
        this.handleReload = this.handleReload.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleDisabled = this.handleDisabled.bind(this)
        this.handleSelectedData = this.handleSelectedData.bind(this)
        this.handleRowData = this.handleRowData.bind(this)
        this.handleOpenDialog = this.handleOpenDialog.bind(this)
        this.getDialogSize = this.getDialogSize.bind(this)
        this.getSingleRoleData = this.getSingleRoleData.bind(this)
    }

    componentDidMount() {
        console.log(this.props)
        this.getData()
        this.prefetchData()
    }

    componentWillUnmount() {
        localStorage.removeItem("searchKey")
        localStorage.removeItem("openedRows")
        localStorage.removeItem("selectedRoleGroup")
    }

    prefetchData = () => {
        Promise.all([
            getSubSector(),
            this.getEmployeesData(),
            getActiveRoles(),
            getRoleGroups()
        ])
            .then(res => {
                var disciplineObj = getLookupObj(res[0])
                var disciplineArr = res[0]
                var allEmpObj = {}
                var allEmpRoleObj = {}
                res[1].forEach(ele => {
                    allEmpObj = { ...allEmpObj, ...{ [ele.id]: ele.first_name + " " + ele.last_name } }
                    allEmpRoleObj = { ...allEmpRoleObj, ...{ [ele.id]: ele.job_title } }
                })
                this.setState({
                    disciplineObj,
                    disciplineArr,
                    allEmp: res[1],
                    allEmpObj: allEmpObj,
                    allEmpRoleObj,
                    addRoleDisabled: false,
                    addEmployeeDisabled: false,
                    contract_start_date: this.props.project.contractor_start_date,
                    contract_end_date: this.props.project.contractor_end_date,
                    project_name: this.props.project.name,
                    project_no: this.props.project.opportunity_id,
                    roles: res[2],
                    rolesObj: getRolesLookupObj(res[2]),
                    roleGroups: res[3]
                }, () => {
                    if (this.empTableRef && this.empTableRef.current) {
                        this.empTableRef.current.disableEmpButton(false)
                    }
                })
            })
            .catch(e => {
                console.log(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getDialogSize = (size) => {
        this.setState({ allocationDialogSize: size })
    }

    closeDialog = () => {
        this.setState({ allocationDialog: false, allocationDialogSize: "xs", addResource: false }, () => {
            this.handleReload()
        })
    }

    handleRowData(data) {
        let empData = JSON.parse(JSON.stringify(data))
        this.setState({
            singleCustomQtyValues: empData.shdl_data,
            selectedEmployee: empData,
            type: empData.type
        })
    }

    handleOpenDialog() {
        this.setState({ singleShowDialog: true })
    }

    handleReload() {
        this.getPRPtemplate()
    }

    handleReset() {
        this.setState({
            multipleSelect: false,
            assignDisabled: true
        })
    }

    handleSelectedData(data) {
        let selectedEmployees = [...this.state.selectedEmployees]
        if (data.is_checked) {
            selectedEmployees.push(data)
        } else {
            for (let i = 0; i < selectedEmployees.length; i++) {
                const element = selectedEmployees[i];
                if (element.id === data.id) {
                    selectedEmployees.splice(i, 1)
                }
            }
        }
        this.setState({
            selectedEmployees: selectedEmployees
        }, () => {
            if (this.state.selectedEmployees.length > 0) {
                this.setState({
                    assignDisabled: false
                })
            } else {
                this.setState({
                    assignDisabled: true
                })
            }
            let customQtyValues = []
            for (let i = 0; i < this.state.selectedEmployees.length; i++) {
                const element = this.state.selectedEmployees[i];
                if (customQtyValues.length === 0) {
                    let shdl_data = []
                    for (let l = 0; l < element.shdl_data.length; l++) {
                        const ite = element.shdl_data[l];
                        if (ite.from_date && ite.to_date) {
                            ite['from'] = new Date(ite.from_date)
                            ite['to'] = new Date(ite.to_date)
                            shdl_data.push(ite)
                        } else {
                            shdl_data.push({ from: this.props.project.contractor_start_date, to: this.props.project.contractor_end_date, qty: 0 })
                        }
                    }
                    customQtyValues.push({
                        role_id: element.role_id,
                        type: element.type,
                        shdl_data: shdl_data
                    })
                } else {
                    let found = customQtyValues.find(ele => ele.role_id === element.role_id)
                    if (found) {
                        let shdl_data = found.shdl_data
                        for (let k = 0; k < element.shdl_data.length; k++) {
                            const item = element.shdl_data[k];
                            if (item.from_date && item.to_date) {
                                item['from'] = new Date(item.from_date)
                                item['to'] = new Date(item.to_date)
                                shdl_data.push(item)
                            } else {
                                shdl_data.push({ from: this.props.project.contractor_start_date, to: this.props.project.contractor_end_date, qty: 0 })
                            }
                            found.shdl_data = shdl_data
                        }
                    } else {
                        let shdl_data = []
                        for (let j = 0; j < element.shdl_data.length; j++) {
                            const item = element.shdl_data[j];
                            if (item.from_date && item.to_date) {
                                item['from'] = new Date(item.from_date)
                                item['to'] = new Date(item.to_date)
                                shdl_data.push(item)
                            } else {
                                shdl_data.push({ from: this.props.project.contractor_start_date, to: this.props.project.contractor_end_date, qty: 0 })
                            }
                        }
                        customQtyValues.push({
                            role_id: element.role_id,
                            type: element.type,
                            shdl_data: shdl_data
                        })
                    }
                }
            }
            this.setState({ customQtyValues: customQtyValues })
        })
    }

    getEmployeesData = () => {
        return new Promise((resolve, reject) => {
            Service.get('/api/ptd/employees/get_emp_data/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    getData() {
        // this.setState({ isLoading: true })
        // Promise.all([
        //     getActiveRoles(),
        //     getRoleGroups()
        // ])
        //     .then(res => {
        //         this.setState({
        //             contract_start_date: this.props.project.contractor_start_date,
        //             contract_end_date: this.props.project.contractor_end_date,
        //             project_name: this.props.project.name,
        //             project_no: this.props.project.opportunity_id,
        //             roles: res[0],
        //             rolesObj: getRolesLookupObj(res[0]),
        //             roleGroups: res[1]
        //         }, () => {
        this.getPRPtemplate()
        //     })
        // })
        // .catch(e => {
        //     console.log(e)
        //     this.setState({ isLoading: false })
        //     this.props.enqueueSnackbar('Something went wrong!', {
        //         variant: 'error'
        //     })
        // })
    }

    handleDisabled(childData) {
        this.setState({
            assignDisabled: childData
        })
    }

    getPRPtemplate = () => {
        this.setState({ isLoading: true })
        Service.get("/api/prp/template/secured/?crm=" + this.props.project.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let prptArray = res.data.roles;
                let newData = []
                this.prpData = prptArray;
                this.setColumns(res.data)
                prptArray.forEach(prptObj => {
                    let dates = {}
                    let prptEmpArray = prptObj.prp_role
                    let allEmpRoleObj = {}
                    prptEmpArray.forEach(empEle => {
                        allEmpRoleObj = { ...allEmpRoleObj, ...{ [empEle.id]: empEle.job_title } }
                    })

                    let set = []
                    for (const item in dates) {
                        set.push(item)
                    }
                    let sorted = set.sort(function (a, b) {
                        // sorts the dates array
                        a = a.toString().split('/');
                        b = b.toString().split('/');
                        return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
                    });

                    let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
                    dates = {
                        ...dates,
                        // total: total.toFixed(2),
                        ...prptObj,
                        ...st_en_date,
                        // project_total: project_total.toFixed(2),
                        // tender_total: tender_total.toFixed(2)
                    }
                    newData.push(dates)
                })

                let sorted = newData;
                // for (let i = 0; i < generalSortOrder.length; i++) {
                //     for (let j = 0; j < newData.length; j++) {
                //         if (this.state.rolesObj[newData[j].role] ? this.state.rolesObj[newData[j].role].includes(generalSortOrder[i]) : undefined) {
                //             sorted.push(newData[j])
                //         }
                //     }
                // }

                // for (let i = 0; i < newData.length; i++) {
                //     let matched = false
                //     for (let j = 0; j < generalSortOrder.length; j++) {
                //         if (this.state.rolesObj[newData[i].role] ? this.state.rolesObj[newData[i].role].includes(generalSortOrder[j]) : undefined) {
                //             matched = true;
                //         }
                //     }
                //     if (!matched) {
                //         sorted.push(newData[i])
                //     }
                // }

                sorted.forEach((item) => item.isChecked = false)
                // sorted.sort((a, b) => (!this.state.rolesObj[a.role] || !this.state.rolesObj[b.role] ? null : this.state.rolesObj[a.role] > this.state.rolesObj[b.role] ? 1 : -1))
                let keys = Object.keys(this.state)
                for (let i = 0; i < keys.length; i++) {
                    const element = keys[i];
                    if (element >= 0) {
                        this.setState({ [element]: false }, () => {
                            // this.setState({ [element]: true })
                        })
                    }
                }
                this.setState({ data: sorted, isLoading: false, copyData: sorted }, () => {
                    if (localStorage.getItem("searchKey")) {
                        this.handleSearchChange(localStorage.getItem("searchKey"))
                    }
                    if (localStorage.getItem('selectedRoleGroup')) {
                        this.setState({ selectedRoleGroup: JSON.parse(localStorage.getItem('selectedRoleGroup')) })
                    }
                    if (localStorage.getItem("openedRows")) {
                        let openedRows = JSON.parse(localStorage.getItem("openedRows"))
                        for (let index = 0; index < openedRows.length; index++) {
                            const element = openedRows[index];
                            this.setState({ [element]: true })
                        }
                    }
                })
                this.copyOfData = newData
                // }, 1000);
            }).catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    openDialog = () => {
        this.setState({ showDialog: true })
    }

    closeShowDialog = () => {
        this.setState({ showDialog: false })
    }

    closeSingleDialog = () => {
        this.setState({ singleShowDialog: false, deleteQtyID: [] })
    }

    openSingleDialog = () => {
        this.setState({ singleShowDialog: true })
    }

    setColumns(data) {
        let column = [
            {
                title: "Role",
                field: "role_name",
                // lookup: this.state.rolesObj,
                headerStyle: {
                    width: "100px", zIndex: 999,
                    left: '100px',
                    background: "#F8F8F8",
                    position: "sticky"
                },
                columnStyle: {
                    width: "100px", zIndex: 99,
                    left: '100px',
                    background: "#F8F8F8",
                    position: "sticky",
                    fontWeight: 'bold'
                },
                cellStyle: { minWidth: "200px" },
            }, {
                title: "Discipline",
                field: "descipline",
                lookup: this.state.disciplineObj,
                headerStyle: { width: "100px" },
            }, {
                title: "Total",
                field: "overall_qty",
                width: 80,
                type: "numeric",
                filtering: false,
                headerStyle: { width: "100px" },
            }, {
                title: "Project Total",
                field: "project_total",
                type: "numeric",
                width: 80,
                filtering: false,
                headerStyle: { width: "100px" },
            }, {
                title: "Tender Total",
                field: "tender_total",
                type: "numeric",
                width: 80,
                filtering: false,
                headerStyle: { width: "100px" },
            }, {
                title: "Start Date",
                field: "min_start_date",
                type: "date",
                width: 100,
                filtering: false,
                headerStyle: { width: "100px" },
            }, {
                title: "End Date",
                field: "max_end_date",
                type: "date",
                width: 100,
                filtering: false,
                headerStyle: { width: "100px" },
            },
        ];

        let reviewTableColumn = [
            {
                title: "Role",
                field: "role",
                width: 100,
                lookup: this.state.rolesObj,
                headerStyle: { minWidth: "200px" },
                cellStyle: { minWidth: "200px" },
            },
            {
                title: "Discipline",
                field: "descipline",
                width: 100,
                lookup: this.state.disciplineObj,
            },
            {
                title: "Employee",
                field: "emp_id",
                width: 100,
                lookup: this.state.allEmpObj,
            },
            {
                title: "Type",
                field: "type",
                width: 100,
                lookup: { 1: "Project", 2: "Tender" },
            },
        ];

        let subTableCol = [
            // { title: "", field: "" },
            // { title: "", field: "" },
            {
                title: "Employee",
                field: "emp_id",
                width: 100,
                lookup: this.state.allEmpObj,
                headerStyle: {
                    width: "100px", zIndex: 99, position: "sticky",
                    left: '120px',
                    background: "#F8F8F8",
                },
            },
            {
                title: "Type",
                field: "type",
                width: 100,
                lookup: { 1: "Project", 2: "Tender" },
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "Total weeks",
                field: "total_weeks",
                width: 400,
                type: "numeric",
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "Start Date",
                field: "start_date",
                type: "date",
                width: 100,
                editable: "never",
                filtering: false,
                headerStyle: { width: "100px" },
                editComponent: (props) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            // autoOk
                            clearable
                            format="dd.MM.yyyy"
                            minDate={data.contractor_start_date}
                            maxDate={data.contractor_end_date}
                            emptyLabel=""
                            value={props.value ? props.value : data.contractor_start_date}
                            onChange={(e) => props.onChange(e)}
                        />
                    </MuiPickersUtilsProvider>
                ),
            },
            {
                title: "End Date",
                field: "end_date",
                type: "date",
                width: 100,
                editable: "never",
                filtering: false,
                headerStyle: { width: "100px" },
                editComponent: (props) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            // autoOk
                            clearable
                            format="dd.MM.yyyy"
                            minDate={data.contractor_start_date}
                            maxDate={data.contractor_end_date}
                            emptyLabel=""
                            value={props.value ? props.value : data.contractor_end_date}
                            onChange={(e) => props.onChange(e)}
                        />
                    </MuiPickersUtilsProvider>
                ),
            },
        ];

        // let dates = this.getWeekOfDates(startDate, endDate);

        this.setState({ weekDateRange: data?.all_dates })

        data.all_dates.map((date, i) => {
            let newObj = {
                title: date,
                field: date,
                type: "numeric",
                sorting: false,
                headerStyle: {
                    // transform: "rotate(270deg)",
                    width: '50px',
                    textAlign: "center",
                },
                cellStyle: {
                    textAlign: "center",
                },
                editable: "never",
                filtering: false,
            };
            column.push(newObj);
            subTableCol.push(newObj);
            reviewTableColumn.push(newObj);
            return null;
        });
        this.setState({
            columns: column,
            subTableCol: subTableCol,
            reviewTableCol: reviewTableColumn,
            // weekDateRange: dates,
        });
    }

    getWeekOfDates = (startDate, endDate) => {

        const dayINeed = 1; // for Monday

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        let dates = []
        while (currDate.diff(lastDate) <= 0) {
            if (
                currDate.isoWeekday() === dayINeed ||
                currDate.format('DD/MM/YYYY') === moment(startDate).startOf('day').format('DD/MM/YYYY')
            ) {
                dates.push(currDate.format('DD/MM/YYYY'));
            }
            currDate.add(1, 'days')
        }
        return dates
    }

    fillDatesWithQty = (dates, qty) => {
        let obj = {};
        dates.forEach(date => {
            obj = { ...obj, [date]: qty }
        })
        return obj;
    }

    handleZoom = (e, v) => {
        this.setState({ zoom: v })
    }

    decreaseZoom = () => {
        let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
        if (zoom < 0.5) return;
        this.setState({ zoom: zoom })

    }

    increaseZoom = () => {
        let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
        if (zoom > 1.2) return;
        this.setState({ zoom: zoom })
    }

    handleChange = (e) => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }

    addRole = (e, rowdata) => {
        this.handleClickOpen()
    }

    deleteAlert = () => {
        this.setState({ rowDelete: true })
    }

    deleteRole = () => {
        this.setState({ deleteDisabled: true, deleteDisabled1: true })
        let idsToDelete = []
        for (let i = 0; i < this.state.deleteRowData.length; i++) {
            const element = this.state.deleteRowData[i];
            if (element) {
                idsToDelete.push(element.id)
            }
        }
        if (idsToDelete.length > 0) {
            Service.get(`${prptandPrprDeleteByPRPtid}${idsToDelete.toString()}`, {
                headers: {
                    Authorization: "Token " + token,
                },

            })
                .then(res => {
                    let dupData = [...this.state.data]
                    let copyData = this.state.copyData.filter(ele => !idsToDelete.includes(ele.id))
                    let data = []
                    for (let i = 0; i < dupData.length; i++) {
                        const element = dupData[i];
                        if (!this.state.deleteRowIndex.includes(i)) {
                            data.push(element)
                        }
                    }

                    this.setState({ data: data, copyData, rowDelete: false, isLoading: false, deleteRowData: [], deleteRowIndex: [], deleteDisabled: true, deleteDisabled1: false }, () => {
                        this.getData()
                        this.props.enqueueSnackbar('Roles deleted successfully.', {
                            variant: 'success'
                        })
                    })
                }).catch(error => {
                    this.setState({ rowDelete: false, isLoading: false, deleteDisabled1: false }, () =>
                        this.props.enqueueSnackbar('Roles deletion failed.', {
                            variant: 'error'
                        }))
                })
        } else {
            this.props.enqueueSnackbar('Please select the roles to delete!.', {
                variant: 'warning'
            })
        }
    }

    addRoleOps = () => {
        for (let i = 0; i < this.state.data.length; i++) {
            if (Number(this.state.data[i].role) === Number(this.state.rolesToPost)
                && Number(this.state.data[i].descipline) === Number(this.state.discipline)) {
                this.props.enqueueSnackbar('Role and Discipline is already added.', {
                    variant: 'warning'
                });
                return;
            }
        }
        this.setState({ addDisabled: true })
        Service.post(prp_template_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                role: this.state.rolesToPost,
                descipline: this.state.discipline,
                crm: this.props.project.id
            }
        })
            .then(res => {
                this.setState({ addDisabled: false })
                this.handleClose();
                this.componentDidMount()
            }).catch(error => {
                console.log(error)
                this.setState({ addDisabled: false })
            })
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }

    handleClose = () => { this.setState({ modelOpen: false }) }

    reviewModalOpen = () => { this.setState({ reviewModal: true }) }

    reviewModalClose = () => { this.setState({ reviewModal: false }) }

    fileToRead(files) {
        if (!files || !files[0]) return;
        let f = files[0];
        try {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* read workbook */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* save data */
                const data = XLSX.utils.sheet_to_json(ws)

                data.forEach(ele => {

                    const isAvailableAlready = (value, availableObj) => {
                        let found = false
                        let foundElement = undefined
                        for (let element in availableObj) {
                            if (availableObj[element] === value) {
                                found = true
                                foundElement = element
                            }
                        }
                        if (!found) {
                            this.props.enqueueSnackbar(`No ${value} present in the system please create one`, { variant: 'error' })
                        } else {
                            return foundElement
                        }
                    }

                    ele.role = isAvailableAlready(ele.role, this.state.rolesObj)
                    ele.discipline = isAvailableAlready(ele.discipline, this.state.disciplineObj)


                    if (ele.employee) {
                        for (let i = 0; i < this.state.allEmp.length; i++) {
                            if (this.state.allEmp[i].emp_no === `${ele.employee}`) {
                                ele.employee = this.state.allEmp[i].id
                            }
                        }
                    }

                    if (ele.type) {
                        if (String(ele.type).trim() === 'Project') {
                            ele.type = 1
                        }
                        if (String(ele.type).trim() === 'Tender') {
                            ele.type = 2
                        }
                    }
                })

                this.makeUplodFormat(data)
            };
            if (rABS) {
                reader.readAsBinaryString(f);
            } else {
                reader.readAsArrayBuffer(f);
            }
        }
        catch (err) {
            this.props.enqueueSnackbar(err.message, { variant: 'error' })
        }
    }

    makeUplodFormat(data) {
        let newarr = [];
        let uploadFormattedData = [];
        data.forEach(emp => {
            let obj = {};
            obj.role = parseInt(emp.role);
            obj.emp_id = emp.employee
            obj.descipline = parseInt(emp.discipline)
            obj.type = emp.type
            obj.role_key = emp.role_key
            obj = { ...obj, crm: this.props.project.id }
            let dates = []
            for (const ele in emp) {
                if (moment(ele, "DD/MM/YYYY").isValid() && emp[ele] > 0) {
                    let date = moment(ele, 'DD/MM/YYYY').format('DD/MM/YYYY')
                    dates.push({ date: date, qty: emp[ele] })
                }
            }
            let sorted = dates.sort(function (a, b) {
                a = a.date.toString().split('/');
                b = b.date.toString().split('/');
                return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
            });

            let shdlData = [];
            let fromDate = null;
            let rangeQty = null;

            sorted.forEach((ele, i) => {
                if (fromDate === null) fromDate = ele.date;
                if (rangeQty === null) rangeQty = ele.qty;
                obj.dates = { ...obj.dates, ...{ [ele.date]: ele.qty } }

                if (ele.qty !== rangeQty || i === sorted.length - 1) {
                    let daterange = {
                        from: moment(fromDate, "DD/MM/YYYY").toDate(),
                        to: i + 1 === sorted.length && sorted.length !== 1 ? (moment(sorted[i - 1].date, "DD/MM/YYYY").toDate()) : (moment(ele.date, "DD/MM/YYYY").toDate()),
                        qty: rangeQty
                    }
                    if (sorted.length === 1) {
                    }
                    shdlData.push(daterange)
                    fromDate = ele.date;
                    rangeQty = ele.qty;
                }
            })
            obj.shdl_data = shdlData;
            uploadFormattedData.push(obj)
            newarr.push({ ...obj, ...obj.dates })
        });
        this.setState({ reviewTableData: newarr, uploadFormattedData })

        // let addedRoles = new Set()
        // for (let j = 0; j < this.state.data.length; j++) {
        //     addedRoles.add(Number(this.state.data[j].role))
        // }

        // let newRoles = new Set();
        // for (let i = 0; i < newarr.length; i++) {
        //     if (!addedRoles.has(Number(newarr[i].role))) {
        //         newRoles.add(newarr[i].role)
        //     }
        // }
        // // this.BulkUpload(newroles, newarr)


        // let rolesBulk = []
        // newRoles.forEach(newrole => {
        //     let obj = {
        //         role: newrole,
        //         descipline: '',
        //         crm: this.props.project.id,
        //     }
        //     newarr.forEach(emp => {
        //         if (newrole === emp.role) {
        //             obj.descipline = emp.descipline;
        //             obj.role_key = emp.role_key
        //         }
        //     })
        //     rolesBulk.push(obj)
        // });
        // this.setState({ newRoles: rolesBulk })
    }

    bulkUpload = () => {
        if (this.state.reviewTableData.length === 0) {
            // this.filterExcelDataEmployee();
            return;
        }
        this.setState({ bulkUploadLoader: true })
        Service.post(conditionalPRPTandPRPRcreate, {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.uploadFormattedData
        })
            .then(res => {
                this.getPRPtemplate()
                this.setState({ bulkUploadLoader: false, reviewModal: false, dropZoneModal: false })
            })
            .catch(error => {
                console.log(error)
                this.setState({ bulkUploadLoader: false })
                this.props.enqueueSnackbar('Something went wrong.', {
                    variant: 'error'
                });
            })
    }

    async filterExcelDataEmployee() {
        this.setState({ bulkUploadLoader: true })
        let roles = [...this.state.data];
        let empExcelData = [...this.state.reviewTableData];
        let dataToUpdate = [];

        /* Iteration of Employee from review tbale data array */
        for (let i = 0; i < empExcelData.length; i++) {
            /*setting CRM id for employee as project id is CRM id for employee in PRP */
            empExcelData[i]['crm'] = this.props.project.id

            let foundInRoles = false;
            let id;
            //  Checking from roles(or PRP table data existed)
            roles.forEach(role => {
                if (role.role === empExcelData[i].role) {
                    // setting employee PRP id as role id is PRP id for employee in PRP table
                    empExcelData[i]['prp'] = role.id;
                }


                let emp = role['emp'];
                for (let k = 0; k < emp.length; k++) {
                    if (empExcelData[i]['emp_id'] && emp[k]['emp_id'] && (empExcelData[i]['emp_id'] === emp[k]['emp_id'])) {
                        foundInRoles = true;
                        id = emp[k]['id']
                        // break;
                    }
                }

            })

            if (foundInRoles) {
                empExcelData[i]['id'] = id
                dataToUpdate.push(empExcelData[i])
            }
        }

        let dataToUpload = []

        for (let i = 0; i < empExcelData.length; i++) {
            let foundInUpdate = false
            for (let j = 0; j < dataToUpdate.length; j++) {
                if (empExcelData[i].role && dataToUpdate[j].role && empExcelData[i].role === dataToUpdate[j].role) {
                    foundInUpdate = true;
                    break;
                }
            }
            if (!foundInUpdate) dataToUpload.push(empExcelData[i])
        }

        if (dataToUpload.length === 0) {
            this.props.enqueueSnackbar('No new data found!', {
                variant: 'error'
            });
            return;
        }
        /* +++++++++++ Start Upload in DB  ++++++++++++++*/
        if (dataToUpload.length > 0) {
            let added = await this.bulkEmployeeAdd(dataToUpload)
            if (added.success && dataToUpdate.length > 0) {
                await this.bulkEmployeeUpdate(dataToUpdate)
            }

            this.setState({ bulkUploadLoader: false, reviewModal: false, dropZoneModal: false });
            getActiveRoles();
        }
        else {
            if (dataToUpdate.length > 0) {
                await this.bulkEmployeeUpdate(dataToUpdate)
                this.setState({ bulkUploadLoader: false, reviewModal: false, dropZoneModal: false });
                getActiveRoles()
            }
        }
        /* ============================== */

    }

    bulkEmployeeAdd(empData) {
        return new Promise((resolve, reject) => {
            Service.post(conditionalPRPTandPRPRcreate, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: empData
            })
                .then(res => {

                    this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully added', {
                        variant: 'success'
                    });

                    resolve({ success: true })
                })
                .catch(error => {
                    console.error(error)
                    if (!error || error.status !== 400) {
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        });
                    }
                    if (error.status === 400) {
                        if (error.data.length) {
                            for (let i = 0; i < error.data.length; i++) {
                                const err = error.data[i]
                                let hasError = Object.keys(err).length !== 0 && err.constructor === Object;
                                if (hasError) {
                                    for (const e in err) {
                                        this.props.enqueueSnackbar("Upload error : " + (empData[i]['emp_id'] ? +'emp_id - ' + empData[i]['emp_id'] : '') + " - " + e + " (" + err[e] + ")", {
                                            variant: 'error'
                                        });
                                        return;
                                    }
                                }
                            }

                        }
                    }
                    reject({ error: false })
                })
        })
    }

    bulkEmployeeUpdate(data) {
        return new Promise((resolve, reject) => {
            Service.patch(prp_roles_api_bulk_update, {
                headers: {
                    Authorization: "Token " + token,
                },
                data
            })
                .then(res => {

                    this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully updated', {
                        variant: 'success'
                    });

                    resolve({ success: true })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    reject({ success: false })
                })
        })
    }

    dropzoneClose = () => {
        this.setState({ dropZoneModal: false })
    }

    handleTotalType = (e) => {
        let { name, checked } = e.target
        this.setState({ [name]: checked })
        let data = [...this.state.data]
        if (checked === true) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]['total']) {
                    data[i]['total'] = (Number(data[i].total) * 8).toFixed(2)
                }
            }
            this.setState({ data: data })
        }
        if (checked === false) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]['total']) {
                    data[i]['total'] = (Number(data[i].total) / 8).toFixed(2)
                }
            }
            this.setState({ data: data })
        }
    }

    tableCellRender(role, columnLabel) {
        // returns a lookup names
        if (columnLabel.field === "role") {
            return columnLabel.lookup[role[columnLabel.field]];
        } else if (columnLabel.field === "descipline") {
            return columnLabel.lookup[role[columnLabel.field]];
        } else if (columnLabel.editable === "never") {
            return role.dates[columnLabel.field];
        }
        return role[columnLabel.field];
    }

    handleSelectionChange(row, index) {
        this.setState({ deleteDisabled: true })
        let data = this.state.data
        let item = data.find((item => item.id === row.id))
        let deleteRowData = this.state.deleteRowData
        let deleteRowIndex = this.state.deleteRowIndex
        if (row.isChecked) {
            deleteRowData = deleteRowData.filter((item => item.id !== row.id))
            deleteRowIndex = deleteRowIndex.filter((item => item !== index))
            item.isChecked = false
        } else {
            item.isChecked = true
            deleteRowIndex.push(row.id)
            deleteRowData.push(row)
        }
        this.setState({ data, deleteRowIndex, deleteRowData }, () => {
            if (deleteRowData.length > 0) {
                this.setState({ deleteDisabled: false })
            } else {
                this.setState({ deleteDisabled: true })
            }
        })
    }

    onOpen = () => {
        this.setState({
            showDialog: true,
            multipleSelect: true
        }, () => {
            if (this.empTableRef && this.empTableRef.current) {
                this.empTableRef.current.dialogFunc()
            }
        })

    }

    handleCustomQty = (e, idx, role_id) => {
        let preObj = [...this.state.customQtyValues]
        for (let i = 0; i < preObj.length; i++) {
            if (preObj[i]['role_id'] === role_id) {
                preObj[i]['shdl_data'][idx]['qty'] = e.target.value;
            }
        }
        this.setState({ customQtyValues: preObj })
    }

    handleCustomToDate = (date, index, i) => {
        if (moment(date).format('dddd') === 'Monday') {
            let data = JSON.parse(JSON.stringify(this.state.customQtyValues))
            data[index].shdl_data[i].to = moment(date).toISOString()
            this.setState({ customQtyValues: data })
        } else {
            this.props.enqueueSnackbar('Please select Monday as To date', {
                variant: 'warning'
            });
        }
    }

    handleCustomFromDate = (date, index, i) => {
        if (moment(date).format('dddd') === 'Monday') {
            let data = JSON.parse(JSON.stringify(this.state.customQtyValues))
            data[index].shdl_data[i].from = moment(date).toISOString()
            this.setState({ customQtyValues: data })
        } else {
            this.props.enqueueSnackbar('Please select Monday as From date', {
                variant: 'warning'
            });
        }
    }

    handleSingleCustomToDate = (date, idx) => {
        if (moment(date).format('dddd') === 'Monday') {
            let preObj = [...this.state.singleCustomQtyValues]
            preObj[idx]['to_date'] = moment(date).toISOString();;
            this.setState({ singleCustomQtyValues: preObj })
        } else {
            this.props.enqueueSnackbar('Please select Monday as To date', {
                variant: 'warning'
            });
        }
    }

    handleSingleCustomFromDate = (date, idx) => {
        if (moment(date).format('dddd') === 'Monday') {
            let preObj = [...this.state.singleCustomQtyValues]
            preObj[idx]['from_date'] = moment(date).toISOString();
            // preObj[idx]['from'] = moment(date).format('DD-MM-YYYY');
            this.setState({ singleCustomQtyValues: preObj })
        } else {
            this.props.enqueueSnackbar('Please select Monday as From date', {
                variant: 'warning'
            });
        }
    }

    handleSingleCustomQty = (e, idx) => {
        let preObj = [...this.state.singleCustomQtyValues]
        preObj[idx]['qty'] = e.target.value;
        this.setState({ singleCustomQtyValues: preObj })
    }

    handleType = (e, idx, role_id) => {
        let preObj = [...this.state.customQtyValues]
        for (let i = 0; i < preObj.length; i++) {
            if (preObj[i]['role_id'] === role_id) {
                preObj[i]['type'] = e.target.value
            }
        }
        this.setState({ customQtyValues: preObj })
    }

    addCustomField = (role_id) => {
        let obj = {
            from: this.state.selectedEmployee.contract_end_date,
            to: this.state.selectedEmployee.contract_end_date,
            qty: 0,
            error: false,
            errorMsg: ''
        }
        let preObj = [...this.state.customQtyValues]
        for (let i = 0; i < preObj.length; i++) {
            if (preObj[i]['role_id'] === role_id) {
                preObj[i]['shdl_data'].push(obj)
            }
        }
        this.setState({ customQtyValues: preObj })
    }

    addSingleCustomField = (role_id) => {
        let obj = {
            from: this.state.selectedEmployee.contract_end_date,
            to: this.state.selectedEmployee.contract_end_date,
            qty: 0,
            error: false,
            errorMsg: ''
        }
        let preObj = [...this.state.customQtyValues]
        for (let i = 0; i < preObj.length; i++) {
            if (preObj[i]['role_id'] === role_id) {
                preObj[i]['shdl_data'].push(obj)
            }
        }
        this.setState({ customQtyValues: preObj })
    }

    removeCustomQty = (e, index, i) => {
        let data = JSON.parse(JSON.stringify(this.state.customQtyValues))
        data[index].shdl_data.splice(i, 1)
        this.setState({ customQtyValues: data })
    }

    removeSingleCustomQty = (idx) => {
        let data = [...this.state.singleCustomQtyValues];
        let deleteQtyID = this.state.deleteQtyID
        // debugger
        let deleted_shdl_data = data.splice(idx, 1);
        for (let index = 0; index < deleted_shdl_data.length; index++) {
            const element = deleted_shdl_data[index];
            if (!(element.from === 'undefined' || element.to === 'undefined' || element.qty === 'undefined' || element.qty === '')) {
                deleteQtyID.push(element.id)
            }
        }
        // deleteQtyID

        this.setState({ singleCustomQtyValues: data, deleteQtyID: deleteQtyID })
    }

    addSingleCustomQty = () => {
        if (!this.state.singleCustomQtyValues) {
            this.setState({
                singleCustomQtyValues: [
                    {
                        from: this.state.selectedEmployee.contract_start_date,
                        to: this.state.selectedEmployee.contract_end_date,
                        qty: 0,
                        error: false,
                        errorMsg: ''
                    }
                ]
            })
        } else {
            let data = [...this.state.singleCustomQtyValues];
            data.push(
                {
                    from: this.state.selectedEmployee.contract_start_date,
                    to: this.state.selectedEmployee.contract_end_date,
                    qty: 0,
                    error: false,
                    errorMsg: ''
                })
            this.setState({ singleCustomQtyValues: data })
        }

    }

    handleSingleType = (e) => {
        this.setState({ type: e.target.value })
    }

    getResource = (role) => {
        this.setState({ role: role }, () => {
            this.setState({ allocationDialog: true })
        })
    }

    prpBulkEdit() {
        this.setState({
            addQtyDisbaled: true
        })
        // let newDates = Object.keys(date)
        let data = [...this.state.selectedEmployees]
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let allDatesQty = {};
            // eslint-disable-next-line no-unused-vars
            let continueToApply = true;
            // let keys = Object.keshdl_datasys(element.dates);
            let i = this.state.customQtyValues.find(x => x.role_id === element.role_id)
            element.shdl_data = i.shdl_data
            element.type = i.type
            // eslint-disable-next-line array-callback-return 
            i.shdl_data.map((dates, i) => {
                let alldates = weekStartDates(dates.from, dates.to);

                alldates.forEach((date) => {
                    let qty = Number(dates.qty);
                    // if (!validQty.includes(qty)) {
                    //     dates.error = true;
                    //     dates.errorMsg = "Qty must be 0.2, 0.4, 0.5, 0.6, 0.8 or 1";
                    //     // return false;
                    //     continueToApply = false
                    //     // allDatesQty = { ...allDatesQty, [date]: qty.toFixed(2) }
                    // }

                    // if (qty > 60) {
                    //     dates.error = true;
                    //     dates.errorMsg =
                    //         "Maximun quantity limit is 60. Please add quantity below 60";
                    //     continueToApply = false;
                    // } else {
                    //     dates.error = false;
                    //     dates.errorMsg = ""
                    // }

                    if (date in allDatesQty) {
                        qty += Number(allDatesQty[date]);

                        // if (qty > 60) {
                        //     dates.error = true;
                        //     dates.errorMsg =
                        //         "Qty value can not be more than 60/week for " + date;
                        //     // return false;
                        //     continueToApply = false;
                        // }
                        // allDatesQty = { ...allDatesQty, [date]: qty.toFixed(2) }
                    }

                    allDatesQty = { ...allDatesQty, [date]: qty.toFixed(1) };
                });
            });
            element.dates = allDatesQty
        }

        this.setState({ selectedData: data }, () => {

            Service.patch(prp_roles_api_bulk_update, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.state.selectedData
            })
                .then(res => {

                    this.props.enqueueSnackbar('Updated Successfully', {
                        variant: 'success'
                    });
                    this.setState({
                        showDialog: false,

                    }, () => {
                        this.setState({
                            addQtyDisbaled: false,
                            customQtyValues: null,
                            multipleSelect: false
                        })
                    })
                    this.setState({ assignDisabled: true, selectedData: [], selectedEmployees: [] })
                    this.closeShowDialog()
                    this.handleReload()
                    // let keys = Object.keys(this.state)
                    // for (let i = 0; i < keys.length; i++) {
                    //     const element = keys[i];
                    //     if (element >= 0) {
                    //         this.setState({ [element]: false })
                    //     }
                    // }
                    // resolve({ success: true })
                })
                .catch(e => {
                    console.error(e)
                    this.setState({
                        addQtyDisbaled: false,
                    })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    // reject({ success: false })
                })
        })
    }

    applyCustomQty = () => {
        this.prpBulkEdit()
    }

    applySingleCustomQty = () => {
        // if (this.state.multipleSelect) {
        //     this.prpBulkEdit()
        // } else {
        this.setState({ addQtyDisbaled: true, })
        try {
            let allDatesQty = {};
            let continueToApply = true;
            this.state.singleCustomQtyValues.forEach((shdlData) => {
                let alldates = weekStartDates(shdlData.from_date, shdlData.to_date);
                alldates.forEach((date) => {
                    let qty = Number(shdlData.qty);

                    // if (qty > 60) {
                    //     shdlData.error = true;
                    //     shdlData.errorMsg =
                    //         "Maximun quantity limit is 60. Please add quantity below 60";
                    //     continueToApply = false;
                    // } else {
                    //     shdlData.error = false;
                    //     shdlData.errorMsg = ""
                    // }

                    if (date in allDatesQty) {
                        qty += Number(allDatesQty[date]);
                        if (qty > 60) {
                            shdlData.error = true;
                            shdlData.errorMsg =
                                "Qty value can not be more than 60/week for " + date;
                            continueToApply = false;
                        }
                    }
                    allDatesQty = { ...allDatesQty, [date]: Number(qty.toFixed(1)) };
                });
            });

            this.setState({ customQtyValues: this.state.customQtyValues });

            if (!continueToApply) {
                this.setState({ addQtyDisbaled: false })
                return;
            }

            if (this.state.deleteQtyID.length > 0) {
                Service.delete(`/api/role/schedule/delete_shdl_data/?id=` + this.state.deleteQtyID.join(','), {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then((res) => {
                        this.setState({ deleteQtyID: [] })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.props.enqueueSnackbar("Something went wrong!", {
                            variant: "error",
                        });
                    })
                    .finally((e) => {
                    });
            }

            let updatedData = []
            for (let i = 0; i < this.state.singleCustomQtyValues.length; i++) {
                const element = this.state.singleCustomQtyValues[i];
                if (element.from_date && element.to_date) {
                    element.from = moment(element.from_date).format("YYYY-MM-DD")
                    element.to = moment(element.to_date).format("YYYY-MM-DD")
                    delete element.from_date
                    delete element.to_date
                    updatedData.push(element)
                }
            }

            Service.put(prp_roles_api + this.state.selectedEmployee.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    shdl_data: updatedData,
                    employee: this.state.selectedEmployee.employee,
                    dates: allDatesQty,
                    type: this.state.type,
                },
            })
                .then((res) => {
                    this.props.enqueueSnackbar("Updated successfully", {
                        variant: "success",
                    });
                    this.setState({ addQtyDisbaled: false, singleShowDialog: false });
                    this.handleReload();

                })
                .catch((error) => {
                    console.log(error)
                    this.setState({ addQtyDisbaled: false, singleShowDialog: false })
                    this.props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                    });
                })
                .finally((e) => {
                });
        } catch (e) {
            this.setState({ addQtyDisbaled: false })
        }
        // }
    };

    handleSearchChange(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.copyData
        }

        for (let i = 0; i < this.state.copyData.length; i++) {
            const element = this.state.copyData[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                // const role = this.state.rolesObj[element.role]
                if (element.role_name) {
                    const role = element.role_name
                    if (role !== undefined)
                        num_of_passed = role.toLowerCase().includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }

            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        if (data.length === 0) {
            localStorage.removeItem("searchKey")
            localStorage.removeItem("openedRows")
        } else {
            localStorage.setItem("searchKey", data)
        }
        this.setState({ data: filtered_data })
    }

    getSingleRoleData = (role) => {
        // Service.get(`/api/prp/template/get_secured_with_role/?crm=${this.props.project.id}&prp_temp=${role.id}`, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        let data = this.state.data
        let findIndex = data.findIndex(x => x.id === role.id)
        if (findIndex >= 0) {
            data.splice(findIndex, 1, role)
            this.setState({ data })
        }
        // })
        // .catch(error => {
        //     console.log(error)
        //     this.props.enqueueSnackbar('Something went wrong!', {
        //         variant: 'error'
        //     });
        // });
    }

    scrolltoTop() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    openBulkUpload = () => {
        this.setState({ dropZoneModal: true })
    }

    handleRoleGroupChange = (value) => {
        if (!value) {
            localStorage.removeItem("selectedRoleGroup")
        } else {
            localStorage.setItem("selectedRoleGroup", JSON.stringify(value))
        }
        this.setState({ selectedRoleGroup: value })
    }

    customRoleFilterAndSearch = (data, rolesSearch) => {
        if (rolesSearch && rolesSearch.role_ids) {
            if (rolesSearch.role_ids.includes(data.role_id)) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    render() {

        const { classes } = this.props;

        const handleClose = () => {
            this.setState({ allocationDialog: false, allocationDialogSize: "xs", addResource: false })
        };

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue })
        }


        return (
            <div>
                {this.state.isLoading ? <RotateCircleLoading color={getPrimaryColor()} /> :
                    <div>
                        <Paper elevation={2} style={{ padding: "4px", marginBottom: "5px", backgroundColor: '#AFD6CB' }}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-evenly'} >
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>Project Name :</b> &nbsp;
                                    {this.state.project_name}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>Project Number :</b>&nbsp;
                                    {this.state.project_no}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>Contract Award Date :</b>&nbsp;
                                    {moment(this.state.contract_start_date).startOf('day').format('DD/MM/YYYY')}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>Contract End Date :</b>&nbsp;
                                    {moment(this.state.contract_end_date).startOf('day').format('DD/MM/YYYY')}
                                </Box>
                            </Box>
                        </Paper>

                        <Paper elevation={2} style={{ padding: "4px", marginBottom: "5px", }}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} >
                                {this.props.addPRPAccess ?
                                    <Button disabled={this.state.addRoleDisabled} size="small" color='primary' variant="contained" onClick={this.addRole} startIcon={<PersonAddIcon />}>
                                        Add Role
                                    </Button>
                                    : null}

                                <Button
                                    startIcon={<WorkIcon />}
                                    disabled={this.state.assignDisabled}
                                    size="small" variant="contained" color='primary' onClick={this.onOpen}>
                                    Assign Selected
                                </Button>

                                <Button color='primary' size="small" variant="contained" disabled={this.state.deleteDisabled} startIcon={<DeleteIcon />} onClick={() => this.deleteAlert()}>
                                    Delete Role
                                </Button>

                                <TextField
                                    autoFocus
                                    autoComplete='off'
                                    size='small'
                                    placeholder="Search"
                                    id="search"
                                    variant='outlined'
                                    value={localStorage.getItem("searchKey") ? localStorage.getItem("searchKey") : ''}
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { localStorage.removeItem("searchKey"); this.handleSearchChange('') }}>
                                                <CloseIcon fontSize='small' />
                                            </IconButton>
                                        </InputAdornment>
                                    }} />

                                <Autocomplete
                                    size='small'
                                    id="combo-box-demo"
                                    options={this.state.roleGroups}
                                    disabled={this.state.addRoleDisabled}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: 300 }}
                                    value={this.state.selectedRoleGroup}
                                    onChange={(e, value) => this.handleRoleGroupChange(value)}
                                    renderInput={(params) => <TextField {...params} label="Role Group" variant="outlined" />} />

                                <ToggleButtonGroup
                                    exclusive
                                    aria-label="text alignment"
                                    style={{ float: 'right', }}
                                    size='small'>
                                    <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                                        <ZoomOut />
                                    </ToggleButton>
                                    <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                                        <ZoomIn />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Paper>

                        <Paper elevation={3} variant={"outlined"}>
                            <div onScroll={(e) => {
                                if (e.target.scrollTop > 50 && !this.state.scrollTop) {
                                    this.setState({ scrollTop: true })
                                } else if (e.target.scrollTop < 50 && this.state.scrollTop) {
                                    this.setState({ scrollTop: false })
                                }
                            }}>
                                <TableContainer style={{ maxHeight: window.innerHeight - (170) }}>
                                    <div ref={this.topRef}>
                                        <Table stickyHeader size='small' style={{ zoom: this.state.zoom }} >
                                            <TableHead>
                                                <TableRow className={this.props.classes.tableRow}>
                                                    <TableCell className={classes.leftSticky}>
                                                        <b>Actions</b>
                                                    </TableCell>
                                                    {this.state.columns.map((columnLabel, inde) => (
                                                        <TableCell
                                                            key={inde}
                                                            style={{
                                                                ...columnLabel.headerStyle,
                                                            }} >
                                                            <b> {columnLabel.title}</b>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.data.filter((item => this.customRoleFilterAndSearch(item, this.state.selectedRoleGroup))).map((row, index) => (
                                                    <>
                                                        <TableRow
                                                            key={index}
                                                            style={{ cursor: 'pointer' }}
                                                            className={this.state[row.id] ? this.props.classes.rows : null}
                                                            onClick={(e) => {
                                                                if (this.state[row.id]) {
                                                                    this.setState({ [row.id]: false }, () => {
                                                                        this.setState({ openedRows: this.state.openedRows.filter(x => x !== row.id) }, () => {
                                                                            localStorage.setItem("openedRows", JSON.stringify(this.state.openedRows))
                                                                        })
                                                                    });
                                                                } else {
                                                                    this.setState({ [row.id]: true }, () => {
                                                                        let openedRows = this.state.openedRows
                                                                        openedRows.push(row.id)
                                                                        this.setState({ openedRows })
                                                                        localStorage.setItem("openedRows", JSON.stringify(openedRows))
                                                                    });
                                                                }
                                                            }} >
                                                            <TableCell className={classes.leftSticky} style={{ zIndex: 99 }}>
                                                                <Box display="flex" justifyContent='center' alignItems="center">
                                                                    <Tooltip title={row.isChecked ? "remove" : 'Select to delete'}>
                                                                        <Checkbox
                                                                            color='primary'
                                                                            size='small'
                                                                            checked={row.isChecked}
                                                                            onClick={(e) => { e.stopPropagation(); this.handleSelectionChange(row, index) }}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </Tooltip>
                                                                    <Tooltip title="Add Employee">
                                                                        <IconButton
                                                                            color='primary'
                                                                            aria-label="Add Employee"
                                                                            disabled={this.state.addEmployeeDisabled}
                                                                            onClick={(e) => {
                                                                                this.setState({
                                                                                    selectedUser: row
                                                                                })
                                                                                e.stopPropagation();
                                                                                this.getResource(row)
                                                                            }}>
                                                                            <GroupAddIcon color='primary' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            </TableCell>
                                                            {this.state.columns.map((columnLabel, i) => (
                                                                <TableCell
                                                                    key={i}
                                                                    style={columnLabel.columnStyle ? columnLabel.columnStyle : columnLabel.headerStyle} >
                                                                    {this.tableCellRender(row, columnLabel)}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                        {this.state[row.id] ? (
                                                            <EmpTable
                                                                disabled={this.handleDisabled}
                                                                ref={this.empTableRef}
                                                                multipleSelect={this.state.multipleSelect}
                                                                roleData={row}
                                                                columns={this.state.subTableCol}
                                                                project={this.props.project}
                                                                allRoles={this.state.rolesObj}
                                                                roles={this.state.roles}
                                                                employees={this.state.allEmp}
                                                                isLoading={this.props.isLoading}
                                                                refreshRoles={getActiveRoles}
                                                                owner_delivery_unit={
                                                                    this.state.owner_delivery_unit
                                                                }
                                                                owner_region={this.state.owner_region}
                                                                sector={this.state.disciplineObj}
                                                                prpData={this.prpData}
                                                                editPRPAccess={this.props.editPRPAccess}
                                                                deletePRPAccess={this.props.deletePRPAccess}
                                                                reload={this.handleReload}
                                                                reset={this.handleReset}
                                                                selectedData={this.handleSelectedData}
                                                                selectedEmployees={this.state.selectedEmployees}
                                                                customQtyValues={this.state.customQtyValues}
                                                                rowData={this.handleRowData}
                                                                openDialog={this.handleOpenDialog}
                                                                getSingleRoleData={this.getSingleRoleData}
                                                            />
                                                        ) : null}
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TableContainer>
                            </div>
                            {this.state.scrollTop ? <IconButton size='large' onClick={() => { this.scrolltoTop() }} variant='contained' style={{ zIndex: 999, position: 'absolute', right: 40, bottom: 40 }}><ArrowUpwardIcon style={{ fontSize: '40px' }} /></IconButton> : null}
                        </Paper>

                        <div>
                            <Dialog open={this.state.modelOpen} onClose={this.handleClose}>
                                <DialogTitle id="form-dialog-title">
                                    Add Role
                                    <IconButton
                                        aria-label="close"
                                        onClick={this.handleClose}
                                        style={{ position: 'absolute', top: '5px', right: '20px' }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Select Role</InputLabel>
                                        <Select
                                            id="rolesToPost"
                                            name="rolesToPost"
                                            value={Number(this.state.rolesToPost)}
                                            onChange={this.handleChange}
                                            label="Select Role"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {this.state.roles.map(role => (
                                                <MenuItem value={role.id}>{role.job_roles}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Select Discipline</InputLabel>
                                        <Select
                                            id="discipline"
                                            name="discipline"
                                            value={Number(this.state.discipline)}
                                            onChange={this.handleChange}
                                            label="Select Discipline"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {this.state.disciplineArr.map(disc => (
                                                <MenuItem value={disc.id}>{disc.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleClose}
                                        color="secondary"
                                        variant='contained'
                                        size='small'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        size='small'
                                        onClick={this.addRoleOps}
                                        disabled={!this.state.rolesToPost || !this.state.discipline || this.state.addDisabled}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={this.state.rowDelete} onClose={() => this.setState({ rowDelete: false })}>
                                <DialogTitle id="form-dialog-title">
                                    Delete Role
                                </DialogTitle>
                                <DialogContent>
                                    <Typography>Are you sure to delete these roles </Typography>
                                    <Box display={'flex'} flexDirection={'column'}>
                                        <ul>
                                            {this.state.deleteRowData.map((value, i) =>
                                                <li key={i}>{value.role_name}</li>
                                            )}
                                        </ul>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => this.setState({ rowDelete: false })}
                                        color="secondary"
                                        variant='contained'
                                        size='small'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color="primary"
                                        onClick={this.deleteRole}
                                        disabled={this.state.deleteDisabled1}
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <Dropzone
                            open={this.state.dropZoneModal}
                            onClose={this.dropzoneClose}
                            onChange={(e) => this.fileToRead(e)}
                            preview={this.reviewModalOpen}
                        />

                        <Modal
                            open={this.state.reviewModal}
                            close={this.reviewModalClose}
                            onUpload={this.bulkUpload}
                            columns={this.state.reviewTableCol}
                            data={this.state.reviewTableData}
                            loading={this.state.bulkUploadLoader}
                            title={"PRP excel data review"}
                        />

                        {/* Qty apply for multiple Employees */}
                        <Dialog open={this.state.showDialog} onClose={this.closeShowDialog} aria-labelledby="form-dialog-title" >
                            <DialogTitle id="form-dialog-title">
                                <Typography variant="h5">Schedule's Bulk Update</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    {this?.state?.customQtyValues?.map((element, index) => (
                                        <Box display={'flex'} flexDirection={'column'} marginBottom={"5px"}>
                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                <Box flexGrow={1} fontSize={'14px'}>
                                                    {/* {this.state.selectedEmployee.employee}&nbsp;({this.state.rolesObj[element.role_id]}) */}
                                                    <b>{this.state.rolesObj[element.role_id]}</b>
                                                </Box>

                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <FormControl variant="outlined" className={classes.formControl} size="small">
                                                        <InputLabel>Type</InputLabel>
                                                        <Select
                                                            id="rolesToPost"
                                                            name="rolesToPost"
                                                            value={Number(element.type)}
                                                            onChange={(e) => {
                                                                this.handleType(e, index, element.role_id)
                                                            }}
                                                            label="Type">
                                                            <MenuItem value={0}>
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                Project
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                Tender
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                {/* <Box marginLeft={'5px'}>
                                                    <IconButton aria-label="close" onClick={() => this.addCustomField(element.role_id)}>
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box> */}
                                            </Box>
                                            <Box marginTop={'5px'}>
                                                {element.shdl_data.map((ele, i) => (
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={2} sm={2}>
                                                            <IconButton aria-label="remove"
                                                                style={{ marginTop: '10px' }}
                                                                onClick={(e) => this.removeCustomQty(e, index, i)}
                                                                disabled={element.shdl_data.length === 1}
                                                            >
                                                                <RemoveIcom />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    format="dd/MM/yyyy"
                                                                    margin="normal"
                                                                    id={'from'}
                                                                    label="From"
                                                                    // minDate={this.props.project.contractor_start_date}
                                                                    // maxDate={this.props.project.contractor_end_date}
                                                                    value={ele.from ? ele.from : undefined}
                                                                    onChange={(e) => this.handleCustomFromDate(e, index, i)}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                    className={classes.date}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    format="dd/MM/yyyy"
                                                                    margin="normal"
                                                                    id={'to'}
                                                                    label="To"
                                                                    // minDate={this.props.project.contractor_start_date}
                                                                    // maxDate={this.props.project.contractor_end_date}
                                                                    value={ele.to ? ele.to : undefined}
                                                                    onChange={(e) => this.handleCustomToDate(e, index, i)}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                    className={classes.date}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        {/* <Grid item xs={12} sm={2} style={{ marginTop: '10px' }}>
                                                            <TextField
                                                                disabled={true}
                                                                id={'qty'}
                                                                label="Qty/Week"
                                                                type="number"
                                                                fullWidth
                                                                margin="dense"
                                                                value={ele.qty}
                                                                onChange={(e) => this.handleCustomQty(e, i, element.role_id)}
                                                            />
                                                        </Grid> */}
                                                        {ele.error === true ?
                                                            <p style={{ marginTop: '0', marginLeft: '100px', color: '#F44336' }}
                                                            >{ele.errorMsg}</p> : null}
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Box>
                                    ))}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeShowDialog} color="secondary" size='small' variant='contained'>
                                    Cancel
                                </Button>
                                <Button disabled={this.state.addQtyDisbaled} color="primary" size='small' variant='contained' onClick={this.applyCustomQty}>
                                    Apply
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/*  Qty apply for single Employee */}
                        <Dialog open={this.state.singleShowDialog} onClose={this.closeSingleDialog} aria-labelledby="form-dialog-title" >
                            <DialogTitle id="form-dialog-title">
                                Add Qty
                            </DialogTitle>
                            <DialogContent>
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                    <Box display={'flex'} flexDirection={'row'}>
                                        <Box flexGrow={1}>
                                            {this.state.selectedEmployee?.emp_last_name ? (this.state.selectedEmployee?.emp_first_name + " " + this.state.selectedEmployee?.emp_last_name) : ''}&nbsp;({this.state.selectedEmployee.role_name})
                                        </Box>
                                        <Box>
                                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                                <InputLabel>Type</InputLabel>
                                                <Select
                                                    id="rolesToPost"
                                                    name="rolesToPost"
                                                    value={Number(this.state.type)}
                                                    onChange={(e) => {
                                                        this.handleSingleType(e)
                                                    }}
                                                    label="Select Type"
                                                >
                                                    <MenuItem value={0}>
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={1}>
                                                        Project
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        Tender
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box marginLeft={'5px'}>
                                            <IconButton aria-label="close"
                                                onClick={() => this.setState({ singleShowDialog: false, addResource: true })}>
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {this.state?.singleCustomQtyValues?.map((element, i) => (
                                        <Grid container spacing={1}>
                                            <Grid item xs={2} sm={2}>
                                                <IconButton aria-label="remove"
                                                    style={{ marginTop: '10px' }}
                                                    onClick={() => this.removeSingleCustomQty(i)}
                                                    disabled={this.state?.singleCustomQtyValues.length === 1}
                                                >
                                                    <RemoveIcom />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        id={'from'}
                                                        label="From"
                                                        // minDate={this.props.project.contractor_start_date}
                                                        // maxDate={this.props.project.contractor_end_date}
                                                        value={element.from_date ? element.from_date : undefined}
                                                        onChange={(e) => this.handleSingleCustomFromDate(e, i)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        className={classes.date}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        id={'to'}
                                                        label="To"
                                                        // minDate={this.props.project.contractor_start_date}
                                                        // maxDate={this.props.project.contractor_end_date}
                                                        value={element.to_date ? element.to_date : undefined}
                                                        onChange={(e) => this.handleSingleCustomToDate(e, i)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        className={classes.date}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={2} style={{ marginTop: '10px' }}>
                                                <TextField
                                                    id={'qty'}
                                                    label="Qty/Week"
                                                    type="number"
                                                    fullWidth
                                                    margin="dense"
                                                    value={element.qty}
                                                    onChange={(e) => this.handleSingleCustomQty(e, i)}
                                                />
                                            </Grid>
                                            {element.error === true ?
                                                <p style={{ marginTop: '0', marginLeft: '100px', color: '#F44336' }}
                                                >{element.errorMsg}</p> : null}
                                        </Grid>
                                    ))}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeSingleDialog} color="secondary" size='small' variant='contained'>
                                    Cancel
                                </Button>
                                <Button disabled={this.state.addQtyDisbaled} color="primary" size='small' variant='contained' onClick={this.applySingleCustomQty}>
                                    Apply
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth={this.state.allocationDialogSize}
                            open={this.state.allocationDialog}
                            TransitionComponent={Transition}
                            disableEnforceFocus >
                            <DialogTitle>
                                <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                        {"Add Employee / Add Demand"}
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Tabs value={this.state.tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                                    <Tab label="Add Employee" />
                                    <Tab label="Add Demand" />
                                </Tabs>
                                </Box>
                                <Box margin={'10px'}>
                                    {this.state.tabValue === 0 ?
                                        <UserAllocation
                                            type={'others'}
                                            getDialogSize={this.getDialogSize}
                                            closeDialog={this.closeDialog}
                                            allocationData={{ prj: { crm_id: this.props.project.id, crm_name: this.props.project.name } }}
                                            role={this.state.role}
                                        /> :
                                        <AllocateSchedules
                                            addDemandwithoutEmployee={true}
                                            getMenu={this.getMenu}
                                            crm={{ 'crm_id': this.props.project.id, 'crm_name': this.props.project.name }}
                                            user={this.state.selectedUser}
                                            closeDialog={this.closeDialog}
                                            allocationData={this.props.allocationData} />
                                    }
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth={this.state.allocationDialogSize}
                            open={this.state.addResource}
                            TransitionComponent={Transition}
                            disableEnforceFocus >
                            <DialogTitle>
                                <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                        {this.state.selectedEmployee?.emp_id ? ("Add Resource to " + this.state.selectedEmployee.emp_first_name + " " + this.state.selectedEmployee.emp_last_name) : "Add Resource"}
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                {this.state.selectedEmployee?.emp_id ?
                                    <UserAllocation
                                        type={'others'}
                                        getDialogSize={this.getDialogSize}
                                        closeDialog={this.closeDialog}
                                        allocationData={
                                            {
                                                prj: { crm: this.props.project.id, crm_name: this.props.project.name },
                                                emp: {
                                                    employee_id: this.state.selectedEmployee.emp_id,
                                                    first_name: this.state.selectedEmployee.emp_first_name,
                                                    last_name: this.state.selectedEmployee.emp_last_name,
                                                    role_name: this.state.selectedEmployee.role_name
                                                },
                                            }}
                                    /> :
                                    <UserAllocation
                                        type={'others'}
                                        getDialogSize={this.getDialogSize}
                                        closeDialog={this.closeDialog}
                                        allocationData={{ prj: { crm_id: this.props.project.id, crm_name: this.props.project.name } }}
                                        role={{
                                            role_id: this.state.selectedEmployee.role_id,
                                            role_name: this.state.selectedEmployee.role_name
                                        }}
                                    />
                                }
                            </DialogContent>
                        </Dialog>

                    </div>
                }
            </div>
        )
    }
}

export default withSnackbar(withStyles(useStyle)(PRPTemplate))