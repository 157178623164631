import { Box, Button, Card, CardContent, Checkbox, DialogActions, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { screenSizeCheck } from '../Helper/CommonThings';
import { getDifferenceInDays, getDifferenceInWeeks } from '../../utils/reuseFunctions';
import moment from 'moment';
import Service from '../../config/networkutils'
import { getCookie } from '../../utils/CookieHelper';
import { Alert } from '@material-ui/lab';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import { Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import OverlapViewGraph from './OverlapViewGraph';
import OverwriteSchedules from './OverwriteSchedules';

const token = getCookie("ptd_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class UnAssignedSchedules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedules: !this.props.schedules ? [] : this.props.schedules,
            addBtnDisabled: false,
            overlapping: false,
            splitDialog: false,
            splitData: null,
            splitStart: '',
            splitEnd: "",
            splitDisabled: false,
            overlapGraph: false,
            overlapData: [],
            deleteDisabled: false,
            deleteAlert: false,
            existingAllocationData: [],
            confirmationDialog: false
        }
    }

    componentDidMount() {
        console.log(this.props)
    //     if (this.props.isPromotion) {
    //         this.fetchUnassignedSchedules()
    //     }
    }

    fetchUnassignedSchedules = () => {
        Service.get(`/api/ptd/employee/promotion/get_unassinged_schlds_for_future_roles_for_one_crm/?crm_id=${this.props.crm.crm_id}&promoted_role_id=${this.props.selectedRole.id}&promotion_date=${moment(this.props.promotionDate).format("YYYY-MM-DD")}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.future_demand && res.data.future_demand.length > 0) {
                    for (let i = 0; i < res.data.future_demand.length; i++) {
                        const element = res.data.future_demand[i];
                        element.isSelected = false
                        for (let j = 0; j < element.shdl_data.length; j++) {
                            const element1 = element.shdl_data[j];
                            element1.isSelected = false
                            element1.updated_qty = element1.qty
                        }
                    }
                    this.setState({
                        schedules: res.data.future_demand,
                    })
                } else {
                    this.setState({
                        schedules: [],
                    })
                }
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    // handleCustomToDate = (date, i, j) => {
    //     if (moment(date).format('dddd') === 'Monday') {
    //         let data = this.state.schedules
    //         data[i].shdl_data[j]['to'] = moment(date).format('YYYY-MM-DD')
    //         this.setState({ schedules: data })
    //     } else {
    //         this.props.enqueueSnackbar('Please select Monday as To date', {
    //             variant: 'warning'
    //         });
    //     }
    // }

    // handleCustomFromDate = (date, i, j) => {
    //     if (moment(date).format('dddd') === 'Monday') {
    //         let data = this.state.schedules
    //         data[i].shdl_data[j]['from'] = moment(date).format('YYYY-MM-DD')
    //         this.setState({ schedules: data })
    //     } else {
    //         this.props.enqueueSnackbar('Please select Monday as From date', {
    //             variant: 'warning'
    //         });
    //     }
    // }

    handleCustomQty = (e, i, j) => {
        let data = this.state.schedules
        if (parseFloat(e.target.value) < 0) {
            this.props.enqueueSnackbar('Qty should not be negative', {
                variant: 'warning'
            });
            return
        }
        if ((parseFloat(e.target.value) > 0) && (parseFloat(e.target.value) > data[i].shdl_data[j]['qty'])) {
            this.props.enqueueSnackbar('Qty should not be greater than actual qty', {
                variant: 'warning'
            });
            data[i].shdl_data[j]['updated_qty'] = data[i].shdl_data[j]['qty']
            this.setState({ schedules: data })
            return
        }
        data[i].shdl_data[j]['updated_qty'] = parseFloat(e.target.value)
        this.setState({ schedules: data })
    }

    handleSetSelect = (e, i) => {
        let data = this.state.schedules
        data[i].shdl_data.map((item) => {
            item.updated_qty = item.qty
            return item
        })
        data[i].isSelected = e.target.checked
        data[i].shdl_data.map((item) => {
            item.isSelected = e.target.checked
            return item
        })
        this.setState({ schedules: data })
    }

    handleIndivSelect = (e, i, j) => {
        let data = this.state.schedules
        data[i].shdl_data[j].isSelected = e.target.checked
        if (!e.target.checked) {
            data[i].isSelected = false
            data[i].shdl_data[j].updated_qty = data[i].shdl_data[j].qty
        }
        this.setState({ schedules: data })
    }

    backToMainMenu() {
        if (this.props.getMenu) {
            this.props.getMenu('main-menu')
        }
    }

    checkOverlappingDates = (data) => {
        let overlapping = false;
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < data.length; j++) {
                const element1 = data[j];
                if (i !== j) {
                    if (element.from <= element1.from && element.to >= element1.from) {
                        overlapping = true
                        break
                    }
                }
            }
        }
        return overlapping
    }

    addSchedules = (is_conflict) => {
        let postData = []
        let schedules = this.state.schedules
        for (let i = 0; i < schedules.length; i++) {
            const element = schedules[i];
            for (let j = 0; j < element.shdl_data.length; j++) {
                const element1 = element.shdl_data[j];
                if (element1.isSelected) {
                    postData.push(element1)
                }
            }
        }
        if (postData.length === 0) {
            this.props.enqueueSnackbar('Please select at least one schedule', {
                variant: 'warning'
            });
        }
        // else if (this.checkOverlappingDates(postData) && !go) {
        //     this.props.enqueueSnackbar('Some of the selected dates are overlapping', {
        //         variant: 'warning'
        //     });
        //     this.setState({ overlapping: true, overlapData: postData })
        // } 
        else {
            this.setState({ addBtnDisabled: true })
            let data = {
                "emp": this.props.user.id,
                "crm": this.props.crm.crm_id,
                'overwrite': is_conflict,
                "shdl_data": postData
            }
            Service.patch('/api/prp/rolenew/allocate_user_to_staff_location_for_existing_shdl/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data
            })
                .then(res => {
                    if (this.props.closeDialog) {
                        this.props.closeDialog()
                    }
                    this.props.enqueueSnackbar('Employee added successfully with the selected unassigned schedules', {
                        variant: 'success'
                    });
                    this.setState({ addBtnDisabled: false, overlapData: [], overlapping: false })
                })
                .catch(error => {
                    if (error && error.status === 302) {
                        this.setState({ existingAllocationData: error.data, confirmationDialog: true })
                    } else if (error && error.status === 406) {
                        this.props.enqueueSnackbar(error.data.message, {
                            variant: 'warning'
                        });
                    } else {
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        })
                    }
                    this.setState({ addBtnDisabled: false })
                    console.log(error)
                })
        }
    }

    openSplit = (data) => {
        this.setState({
            splitData: data,
            splitStart: moment(data.from).format('YYYY-MM-DD'),
            splitEnd: moment(data.to).format('YYYY-MM-DD'),
            splitDialog: true
        })
    }

    handleCustomFromDate = (date) => {
        if (moment(date).format('dddd') === 'Monday') {
            this.setState({ splitStart: moment(date).format('YYYY-MM-DD') })
        } else {
            this.props.enqueueSnackbar('Please select Monday as From date', {
                variant: 'warning'
            });
        }
    }

    handleCustomToDate = (date) => {
        if (moment(date).format('dddd') === 'Monday') {
            this.setState({ splitEnd: moment(date).format('YYYY-MM-DD') })
        } else {
            this.props.enqueueSnackbar('Please select Monday as To date', {
                variant: 'warning'
            });
        }
    }

    splitData = () => {
        if (!this.state.splitStart || !this.state.splitEnd) {
            this.props.enqueueSnackbar('Please select From and To date', {
                variant: 'warning'
            });
        } else if (moment(this.state.splitStart).isSame(this.state.splitData.from) && moment(this.state.splitEnd).isSame(this.state.splitData.to)) {
            this.props.enqueueSnackbar('dates should not be same as the original dates', {
                variant: 'warning'
            });
        } else if (moment(this.state.splitStart).isAfter(this.state.splitEnd)) {
            this.props.enqueueSnackbar('From date should be less than To date', {
                variant: 'warning'
            });
        } else {
            this.setState({ splitDisabled: true })
            let data = {
                "id": this.state.splitData.id,
                "from": this.state.splitStart,
                "to": this.state.splitEnd
            }
            Service.patch('/api/prp/rolenew/split_role_shdl/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data
            })
                .then(res => {
                    if (this.props.refreshData) {
                        this.props.refreshData()
                    }

                    if (this.props.isPromotion) {
                        this.fetchUnassignedSchedules()
                    }

                    this.props.enqueueSnackbar('Dates splitted successfully!', {
                        variant: 'success'
                    });
                    this.setState({ splitDisabled: false, splitData: null, splitDialog: false })
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                    this.setState({ splitDisabled: false })
                    console.log(error)
                })
        }
    }

    deletedSchedules(go) {
        let schedules = this.state.schedules
        let deletedSchedules = []
        for (let i = 0; i < schedules.length; i++) {
            const element = schedules[i];
            for (let j = 0; j < element.shdl_data.length; j++) {
                const element1 = element.shdl_data[j];
                if (element1.isSelected) {
                    deletedSchedules.push(element1.id)
                }
            }
        }

        if (deletedSchedules.length === 0) {
            this.props.enqueueSnackbar('Please select at least one schedule', {
                variant: 'warning'
            });
        } else if (deletedSchedules.length > 0 && !go) {
            this.setState({ deleteAlert: true })
        } else {
            this.setState({ deleteDisabled: true })
            Service.delete(`/api/role/schedule/delete_shdl_data/?id=${deletedSchedules}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    if (this.props.refreshData) {
                        this.props.refreshData()
                    }

                    if (this.props.isPromotion) {
                        this.fetchUnassignedSchedules()
                    }
                    this.props.enqueueSnackbar('Schedules deleted successfully!', {
                        variant: 'success'
                    });
                    this.setState({ deleteDisabled: false, deleteAlert: false })
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ deleteDisabled: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                });
        }
    }

    continueAction = () => {
        let postData = []
        let schedules = this.state.schedules
        for (let i = 0; i < schedules.length; i++) {
            const element = schedules[i];
            for (let j = 0; j < element.shdl_data.length; j++) {
                const element1 = element.shdl_data[j];
                if (element1.isSelected) {
                    postData.push(element1)
                }
            }
        }
        if (postData.length === 0) {
            this.props.enqueueSnackbar('Please select at least one schedule', {
                variant: 'warning'
            });
        } else {
            this.setState({ addBtnDisabled: true })
            if (this.props.isPromotion) {
                this.props.isPromotion(postData)
            }
        }
    }

    render() {

        const handleClose = () => {
            this.setState({ splitDialog: false, splitData: null })
        };

        return (
            <div>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                    {!this.props.isPromotion ?
                        <div>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Alert severity="info" width='100%'>
                                    Below given schedules are the unassigned schedules for the selected crm - <b>{this.props.crm.crm_name}</b> and  the selected Employees's role - <b>{this.props.user.role_name}</b>
                                    <br />If you want any of the these schedules to be assigned to the selected employee, please select the schedules and click on the Add button or if you want to create a new schedule, please click on the Create New button.
                                </Alert>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'} width={'100%'} >
                                <h3>{this.props.crm.crm_name + " --> " + this.props.user.last_name + ", " + this.props.user.first_name + " (" + this.props.user.role_name + ")"}</h3>
                            </Box>
                        </div> : null}

                    <Box display={'flex'} flexDirection={'row'} width={'100%'} >
                        <Grid container spacing={2}>
                            {(this.state.schedules).map((value, i) =>
                                <Grid item xs={screenSizeCheck() ? 3 : 4}>
                                    <Card elevation={6} style={{ borderRadius: '10px' }}>
                                        <CardContent>
                                            <Box display={'flex'} flexDirection={'column'}>
                                                <Box display={'flex'} flexDirection={'row'} >
                                                    <Checkbox
                                                        checked={value.isSelected}
                                                        onChange={(e) => this.handleSetSelect(e, i)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />

                                                </Box>
                                                {
                                                    value.shdl_data.map((item, j) =>
                                                        <Box alignItems='center' display='flex' flexDirection='row' m={1}>
                                                            <Box marginRight={'8px'}>
                                                                <Checkbox
                                                                    checked={item.isSelected}
                                                                    onChange={(e) => this.handleIndivSelect(e, i, j)}
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                />
                                                                <Tooltip title={(getDifferenceInWeeks(item.from, item.to)) <= 1 ? "You can't split single week" : "Split Dates"}>
                                                                    <IconButton size='small' disabled={(getDifferenceInWeeks(item.from, item.to)) <= 1} onClick={() => this.openSplit(item)}>
                                                                        <CallSplitIcon fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>

                                                            <Box display={'flex'} flexDirection={'column'}>
                                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                                    <Box marginRight='8px'>
                                                                        <b>From: </b> {moment(item.from).format('DD-MM-YYYY')}
                                                                    </Box>
                                                                    <Box marginRight='8px'>
                                                                        <b>To: </b> {moment(item.to).format('DD-MM-YYYY')}
                                                                    </Box>
                                                                    <Box>
                                                                        <b>Qty: </b> {item.qty}
                                                                    </Box>
                                                                </Box>
                                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={1}>
                                                                    <Box marginRight='8px'>
                                                                        <b>No of days: </b> {getDifferenceInDays(item.from, item.to)}
                                                                    </Box>
                                                                    <Box marginRight='8px'>
                                                                        <b>No of weeks: </b> {getDifferenceInWeeks(item.from, item.to)}
                                                                    </Box>

                                                                </Box>
                                                            </Box>
                                                            {
                                                                item.isSelected ?
                                                                    <Box marginLeft={'8px'}>
                                                                        <TextField
                                                                            id={'qty'}
                                                                            label="Qty"
                                                                            size='small'
                                                                            type="number"
                                                                            style={{ width: 70 }}
                                                                            margin="dense"
                                                                            value={item.updated_qty}
                                                                            onChange={(e) => this.handleCustomQty(e, i, j)}
                                                                            variant='outlined'
                                                                        />
                                                                    </Box> : null
                                                            }
                                                        </Box>
                                                    )}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} m={2}>
                        {((this.props.allocationData?.prj) || (this.props.isPromotion)) ? null :
                            <Button size='small' variant="contained" color="primary" onClick={() => this.backToMainMenu()} style={{ marginRight: "10px" }}>
                                Back
                            </Button>}

                        <Button disabled={this.state.deleteDisabled} size='small' variant="contained" color="primary" onClick={() => this.deletedSchedules(false)} style={{ marginRight: "10px" }}>
                            Delete
                        </Button>

                        <Button size='small' variant="contained" color="primary" onClick={() => { if (this.props.getMenu) this.props.getMenu('') }} style={{ marginRight: "10px" }}>
                            Create New
                        </Button>

                        {this.props.isPromotion ?
                            <Button style={{ marginRight: "10px" }} size='small' variant="contained" color="primary" disabled={this.state.addBtnDisabled} onClick={() => this.continueAction()}>
                                Continue
                            </Button>
                            :
                            <Button size='small' variant="contained" color="primary" disabled={this.state.addBtnDisabled} onClick={() => this.addSchedules(false)}>
                                Add
                            </Button>}
                    </Box>
                </Box>

                <Dialog
                    fullWidth
                    maxWidth={'xs'}
                    open={this.state.splitDialog}
                    TransitionComponent={Transition}
                    disableEnforceFocus
                >
                    <DialogTitle>
                        Split Dates
                    </DialogTitle>
                    <DialogContent>
                        <Box alignItems='center' display='flex' flexDirection='row' justifyContent={'center'} m={1}>
                            <Box marginRight={'10px'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id={'from'}
                                        label="From"
                                        value={this.state.splitStart}
                                        onChange={(e) => this.handleCustomFromDate(e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={this.state.splitData?.from}
                                        maxDate={this.state.splitData?.to}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box marginRight={'10px'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id={'to'}
                                        label="To"
                                        value={this.state.splitEnd}
                                        onChange={(e) => this.handleCustomToDate(e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={this.state.splitData?.from}
                                        maxDate={this.state.splitData?.to}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" size='small' variant='contained'>
                            Close
                        </Button>
                        <Button onClick={() => this.splitData()} color="primary" size='small' disabled={this.state.splitDisabled} variant='contained'>
                            Split
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    maxWidth={'xl'}
                    open={this.state.overlapping}
                    // onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <DialogTitle>
                        Verify Overlapping Dates
                    </DialogTitle>
                    <DialogContent>
                        <OverlapViewGraph
                            data={this.state.overlapData}
                            selectedCrm={this.props.crm}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ overlapping: false }) }} color="primary" size='small' variant='contained'>
                            Close
                        </Button>
                        <Button onClick={() => this.addSchedules(true)} color="primary" size='small' disabled={this.state.addBtnDisabled} variant='contained'>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    maxWidth={'xs'}
                    open={this.state.deleteAlert}
                    TransitionComponent={Transition}
                >
                    <DialogTitle>
                        Are you sure, Do you want to delete the selected schedules?
                    </DialogTitle>

                    <DialogActions>
                        <Button onClick={() => { this.setState({ deleteAlert: false }) }} color="primary" size='small' variant='contained'>
                            Close
                        </Button>
                        <Button disabled={this.state.deleteDisabled} onClick={() => { this.deletedSchedules(true) }} color="primary" size='small' variant='contained'>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog maxWidth="lg" open={this.state.confirmationDialog} onClose={() => this.setState({ confirmationDialog: false })}>
                    <DialogTitle>Are you sure? Do you want to overwrite the existing schedule?</DialogTitle>
                    <DialogContent>
                        <OverwriteSchedules errorData={this.state.existingAllocationData} />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.addBtnDisabled} onClick={() => this.addSchedules(true)} color="primary" size='small' variant='contained'>
                            Proceed
                        </Button>
                        <Button onClick={() => this.setState({ confirmationDialog: false })} color="primary" size='small' variant='contained'>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default withSnackbar(UnAssignedSchedules);