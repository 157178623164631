import {
    crm_api,
    roles_api,
    users_api,
    owner_region_sector_api,
    sub_sector_api,
    owner_delivery_api,
    market_sub_vertical_api,
    otherTasks,
    ptdEmployeesLeave,
    sales_stage_api,
    // generalforeman,
    roles_group_api,
    regionHistory,
    generalforemanGang,
    getElectricityOHLTcRMs,
    prprSchedulesBulkUpdate,
    get_foreman_by_role
} from "./apiList"
import Service from './networkutils'
import { getCookie } from "../utils/CookieHelper";
const token = getCookie("ptd_ts_token");


export const getEmployees = () => {

    return new Promise((resolve, reject) => {
        Service.get(users_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getRoles = () => {

    return new Promise((resolve, reject) => {
        Service.get(roles_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getActiveRoles = () => {

    return new Promise((resolve, reject) => {
        Service.get(roles_api + '?is_active=true', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}


export const getRoleGroups = () => {

    return new Promise((resolve, reject) => {
        Service.get(`/api/prp/template/get_secured_with_role_group/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getCRM = () => {

    return new Promise((resolve, reject) => {
        Service.get(crm_api + '?is_trashed=false', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })

}

export const getNotTrashedCrm = () => {

    return new Promise((resolve, reject) => {
        Service.get(crm_api + '?stage=5&is_trashed=false', {

            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getNotTrashedCrmWithNoFilter = () => {

    return new Promise((resolve, reject) => {
        Service.get(crm_api + '?is_trashed=false', {

            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getCRMTableData = () => {
    return new Promise((resolve, reject) => {
        Service.get(crm_api + '?is_trashed=false', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                let data = res.data;
                let completedOppertunities = [];
                data.forEach(opportunity => {
                    if (opportunity.archive_reason > 0) {
                        completedOppertunities.push(opportunity)
                    }
                    return null;
                })
                resolve(completedOppertunities)

            }).catch(error => reject(error))
    })

}



export const getOwnerDelivery = () => {
    return new Promise((resolve, reject) => {
        Service.get(owner_delivery_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getEmployeeRegionWithHistory = () => {
    return new Promise((resolve, reject) => {
        Service.get(regionHistory, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
                // this.allEmpRegionHistory = res.data
            })
            .catch(error => reject(error))
    })
}

export const getSubSector = () => {
    // GET dicipline also same
    return new Promise((resolve, reject) => {
        Service.get(sub_sector_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getMarketSubVerticle = () => {
    return new Promise((resolve, reject) => {
        Service.get(market_sub_vertical_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}


export const getEmployeesLeaves = () => {

    return new Promise((resolve, reject) => {
        Service.get(ptdEmployeesLeave , {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}


export const getOtherTasks = () => {

    return new Promise((resolve, reject) => {
        Service.get(otherTasks, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getSalesStage = () => {
    return new Promise((resolve, reject) => {
        Service.get(sales_stage_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getRegion = () => {
    return new Promise((resolve, reject) => {
        Service.get(owner_region_sector_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getGeneralforeman = (discipline) => {
    return new Promise((resolve, reject) => {
        Service.get(get_foreman_by_role + "?discipline=" + discipline, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => resolve(res.data)).catch(error => reject(error))
    })
}

export const getGeneralforemanGang = () => {
    return new Promise(
        (resolve, reject) => {
            Service.get(generalforemanGang, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        }
    )
}


export const getRolesGroup = () => {
    return new Promise((resolve, reject) => {
        Service.get(roles_group_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getAllElectricityOHLTcRMs = () => {
    return new Promise((resolve, reject) => {
        Service.get(getElectricityOHLTcRMs, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const getAllGateFiveCRMs = () => {
    return new Promise((resolve, reject) => {
        Service.get(crm_api + '?stage=5&is_trashed=false', {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}

export const prprBulkUpdate = (prprIdGroupedObj) => {
    return new Promise((resolve, reject) => {
        Service.patch(prprSchedulesBulkUpdate, {
            headers: {
                Authorization: "Token " + token,
            },
            data: prprIdGroupedObj
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(error => reject(error))
    })
}
