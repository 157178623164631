import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem, TextField, } from '@material-ui/core';
import { generalforeman } from '../../../../config/apiList';
import Service from '../../../../config/networkutils';
import { withSnackbar } from "notistack";
import { getCookie } from '../../../../utils/CookieHelper';
import MaterialTable from 'material-table';
import { CoffeeLoading, LoopCircleLoading } from 'react-loadingg';
import { getSecondaryColor } from '../../../Helper/CommonThings';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
// import { getActiveRoles, getCRM, getEmployees, getGeneralforeman, getOwnerDelivery, getRegion, getSubSector } from '../../../../config/PromisesHandler';
// import { getIdFnameLnameObj, getIdNameFomattedObj } from '../../../../utils/reuseFunctions';

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"));

const useStyle = (theme) => ({
    headRow: {
        backgroundColor: "#D5E1E5",
        // minWidth: '100%'
    },
    selectedRow: {
        backgroundColor: "#EDFAFF",
    },
})


class OHLStaff extends React.Component {
    state = {
        gfs: this.props.gfs,
        gfsCopy: this.props.gfsCopy,
        allGfs: this.props.allGfs,
        showGFAdd: false,
        isLoading: false,
        allOHLEmp: [],
        value: {},
        name: null,
        anchorEl: null,
        search: '',
        unAllocatedGF: this.props.unAllocatedGF,
        replaceGFdialog: false,
        replaceLoading: false,
        excelExportDialog: false
    }

    gfRoleId = null
    ohlroles = null

    postOps(data) {
        Service.post(generalforeman, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        }).then(res => {
            let updatedGfs = [...this.state.gfs]
            let Notallocated = [...this.state.unAllocatedGF]
            updatedGfs.push(res.data)
            let removedTheAdded = Notallocated.filter(emp => emp.id !== res.data.ptd_employee)
            this.setState({ gfs: updatedGfs, gfsCopy: updatedGfs, unAllocatedGF: removedTheAdded, showGFAdd: false }, () => {
                if (this.props.updateParent) {
                    this.props.updateParent(this.state.gfs, this.state.gfsCopy, this.state.allGfs, this.state.unAllocatedGF)
                }
                this.props.enqueueSnackbar('Client added successfully', {
                    variant: 'success'
                })
            })
        })

            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    deleteOps(data) {
        if (!data) {
            this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
        } else {
            Service.delete(generalforeman + data.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                }
            }).then(res => {
                let gffs = [...this.state.gfs]
                gffs = gffs.filter(gf => gf.id !== data.id)
                let dupGF = [...this.state.allGfs]
                dupGF = dupGF.filter(gf => gf.id === data.ptd_employee)
                let dupUnAllocatedGF = [dupGF[0], ...this.state.unAllocatedGF]
                this.props.enqueueSnackbar('Delete operation Success !', { variant: 'success' })
                this.setState({ gfs: gffs, gfsCopy: gffs, unAllocatedGF: dupUnAllocatedGF, anchorEl: null }, () => {
                    if (this.props.updateParent) {
                        this.props.updateParent(this.state.gfs, this.state.gfsCopy, this.state.allGfs, this.state.unAllocatedGF)
                    }
                })
            }).catch(error => {
                console.log(error)
                this.setState({ anchorEl: null })
                this.props.enqueueSnackbar('Delete operation failed !', { variant: 'error' })
            })
        }
    }

    replaceOps(data) {
        this.setState({
            replaceLoading: true
        })
        if (!data) {
            this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
        } else {
            Service.patch('/api/ohl/gang/gang_leader_change/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    general_forman_id: this.state.value.id,
                    ptd_emp_id: data.id
                }
            }).then(res => {
                let gffs = [...this.state.gfs]
                gffs = gffs.filter(gf => gf.id !== this.state.value.id)
                let dupGF = [...this.state.allGfs]
                // let employee = dupGF.filter(gf => gf.id === data.id)
                gffs.push(res.data)
                dupGF = dupGF.filter(gf => gf.id === this.state.value.ptd_employee)
                let unAllocatedGF = [...this.state.unAllocatedGF]
                unAllocatedGF = unAllocatedGF.filter(gf => gf.id !== data.id)
                if (dupGF.length > 0) {
                    dupGF[0].tableData = { id: 0 }
                    let dupUnAllocatedGF = [dupGF[0], ...unAllocatedGF]
                    this.props.enqueueSnackbar('Replace operation Success !', { variant: 'success' })
                    this.setState({ gfs: gffs, gfsCopy: gffs, unAllocatedGF: dupUnAllocatedGF, anchorEl: null, replaceGFdialog: false }, () => {
                        if (this.props.updateParent) {
                            this.props.updateParent(this.state.gfs, this.state.gfsCopy, this.state.allGfs, this.state.unAllocatedGF)
                        }
                        this.setState({
                            replaceLoading: false
                        })
                    })
                }
            }).catch(error => {
                console.log(error)
                this.setState({ anchorEl: null, replaceGFdialog: false, replaceLoading: false })
                this.props.enqueueSnackbar('Replace operation failed !', { variant: 'error' })
            })
        }
    }

    // handleClick(value) {
    //     this.props.data(value)
    // }

    handleMenuClick(event, value, name) {
        this.setState({
            value: value,
            name: name,
            anchorEl: event.currentTarget

        }, () => {
            this.props.name(name)
        })
    }

    exportExcel() {
        console.log(this.state)
        let roles_sectors_regions_OD_uri = "/api/prp/rolenew/get_staff_location_data/?archive_reason=0&get_report=true&general_foreman=" + this.state.value.id + '&is_active=True';

        Service.get(roles_sectors_regions_OD_uri, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    excelExportDialog: true
                })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    handleOptions(option) {
        this.props.option(option)
        this.props.data(this.state.value)
    }

    handleClose() {
        this.setState({
            anchorEl: null
        })
    }

    search(data) {
        this.setState({
            search: data
        })
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.gfsCopy
        }

        for (let i = 0; i < this.state.gfsCopy.length; i++) {
            const element = this.state.gfsCopy[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                // this.state.ohlEempObj[value.ptd_employee]
                num_of_passed = this.props.ohlEempObj[element.ptd_employee].toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ gfs: filtered_data })
        // if(data.length > 0){
        //     this.setState({
        //         showLiveAll : true
        //     })
        // }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                        <div>
                            <Box display='flex' justifyContent={'left'} alignItems={'center'}>
                                <Box flexGrow={1}>
                                    <TextField
                                        variant='outlined'
                                        placeholder='Search'
                                        size="small"
                                        value={this.state.search}
                                        onChange={(event) => this.search(event.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {this.state.search.length === 0 ?
                                                        <SearchIcon /> :
                                                        <IconButton
                                                            onClick={() => this.setState({
                                                                gfs: this.state.gfsCopy,
                                                                search: ''
                                                            },)}
                                                        >
                                                            <CloseIcon fontSize="small" />
                                                        </IconButton>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        onClick={() => this.setState({ showGFAdd: true })}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                    >
                                        {/* Add GF/SGF */}
                                        Add
                                    </Button>
                                </Box>
                            </Box>
                            <Box marginTop={2}>
                                <Paper elevation={3} variant={"outlined"}>
                                    {/* <MaterialTable
                                title="General foremen and their Gangs"
                                columns={[{ title: 'General Foremen', field: 'ptd_employee', lookup: this.state.ohlEempObj }]}
                                data={this.state.gfs}
                                options={{
                                    search: true,
                                    paging: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    // fixedColumns: {
                                    //     left: 1,
                                    //     right: 0
                                    // },
                                    // exportButton: { csv: true, pdf: false },
                                    // filtering: true
                                }}
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add General Foreman',
                                        isFreeAction: true,
                                        onClick: () => { this.setState({ showGFAdd: true }) }
                                    }
                                ]}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.deleteOps(oldData)
                                                resolve()
                                            }, 1000)
                                        }),


                                }}
                                detailPanel={rowData => (
                                    // <DemandVsCapacityGraph rowData={rowData} columns={this.state.demandVsCapacityColArr} role={this.state.roles} />
                                    <OHLGang
                                        ohlEmpObj={this.state.ohlEempObj}
                                        allOHLEmp={this.state.allGangEmps}
                                        allSectors={this.state.allSectors}
                                        allSectorLupObj={this.state.allSectorLupObj}
                                        owner_delivery_unit={this.state.owner_delivery_unit}
                                        owner_region={this.state.owner_region}
                                        gf={rowData}
                                        rolesObj={this.state.rolesObj}
                                        allActiveRoles={this.state.allActiveRoles}
                                        getActiveRoles={getActiveRoles}
                                        allCRMS={this.state.allCRMS}
                                    />
                                )}
                            >

                            </MaterialTable> */}
                                    <div>
                                        {this.state.gfs.length === 0 ?
                                            <Box margin={1} display='flex' justifyContent='center' alignItems='center' fontSize='large'>
                                                No Such Members are Present
                                            </Box> :
                                            <List dense={true}>
                                                {this.state.gfs.map((value, index) => {
                                                    return (
                                                        <div>
                                                            <ListItem>
                                                                <ListItemText style={{ padding: "10px" }}
                                                                    primary={this.props.ohlEempObj[value.ptd_employee]}
                                                                    secondary={null}
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton edge="end" onClick={(event) => this.handleMenuClick(event, value, this.props.ohlEempObj[value.ptd_employee])}>
                                                                        <MoreHorizIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>

                                                            </ListItem>
                                                            <div>
                                                                {index !== this.state.gfs.length - 1 ?
                                                                    <Divider variant="middle" style={{ backgroundColor: getSecondaryColor() }} /> : null}
                                                            </div>
                                                        </div>)
                                                })}
                                            </List>}
                                        <div>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={this.state.anchorEl}
                                                open={Boolean(this.state.anchorEl)}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                onClose={() => this.handleClose()}
                                            >
                                                <MenuItem onClick={() => this.handleOptions(0)}>All Gang Members</MenuItem>
                                                <MenuItem onClick={() => this.handleOptions(1)}>Standby Gang</MenuItem>
                                                <MenuItem onClick={() => this.handleOptions(2)}>Projects</MenuItem>
                                                <MenuItem onClick={() => this.deleteOps(this.state.value)}>Delete</MenuItem>
                                                <MenuItem onClick={() => this.setState({ replaceGFdialog: true })}>Replace</MenuItem>
                                                <MenuItem onClick={() => {this.handleClose();this.exportExcel()}}>Export Resource</MenuItem>
                                            </Menu>
                                        </div>
                                    </div>

                                    <Dialog open={this.state.showGFAdd} onClose={() => this.setState({ showGFAdd: false })} maxWidth={"lg"}>
                                        <DialogTitle>
                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
                                                <Box flexGrow={1} fontWeight={500} fontSize={18} marginRight={'5px'}>
                                                    {/* Add General Foreman/Senior General Foremen  */}
                                                    Add Staff
                                                </Box>
                                                <IconButton onClick={() => this.setState({ showGFAdd: false })}><CloseIcon color='error' /></IconButton>
                                            </Box>
                                        </DialogTitle>
                                        <DialogContent>
                                            <MaterialTable
                                                // title={<Alert severity="info">Only Live General Foremen and Senior General Foremen are shown below!</Alert>}
                                                title={<Alert severity="info">Only Live Employees are shown below!</Alert>}
                                                columns={[
                                                    { title: 'Employee No', field: 'emp_no', },
                                                    { title: 'First Name', field: 'first_name', },
                                                    { title: 'Last Name', field: 'last_name', },
                                                    { title: 'Email Address', field: 'email', },
                                                    { title: 'Role', field: 'job_title', lookup: this.props.rolesObj },
                                                    { title: 'Owner Region', field: 'owner_region', lookup: this.props.owner_region },
                                                    { title: 'Owner Delivery Unit', field: 'owner_delivery_unit', lookup: this.props.owner_delivery_unit },
                                                    { title: 'Sector', field: 'sector', lookup: this.props.sector },
                                                    { title: 'Start Date', field: 'start_date', type: 'date' },
                                                    { title: 'End Date', field: 'end_date', type: 'date' },
                                                ]}
                                                data={this.state.unAllocatedGF}
                                                isLoading={this.state.isLoading}
                                                options={{
                                                    headerStyle: {
                                                        fontWeight: 'bold',
                                                    },
                                                    padding: 'dense',
                                                    // showTitle: false,
                                                    // searchFieldAlignment: 'left',
                                                    paging: false,
                                                    pageSize: 20
                                                }}
                                                onRowClick={(event, rowData, togglePanel) => {
                                                    this.postOps({
                                                        ptd_employee: rowData.id,
                                                        discipline: this.props.discipline
                                                    })
                                                }}
                                            />
                                        </DialogContent>
                                    </Dialog>

                                    <Dialog open={this.state.replaceGFdialog} onClose={() => this.setState({ replaceGFdialog: false })} maxWidth={"lg"}>
                                        <DialogTitle>
                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
                                                <Box flexGrow={1} fontWeight={500} fontSize={18}>
                                                    Select the General Foreman for the replacement of {this.props.ohlEempObj[this.state.value.ptd_employee]}
                                                </Box>
                                                <IconButton onClick={() => this.setState({ replaceGFdialog: false })}><CloseIcon color='error' /></IconButton>
                                            </Box>
                                        </DialogTitle>
                                        <DialogContent>
                                            <MaterialTable
                                                title={<Alert severity="info">Only Live Employees are shown below!</Alert>}
                                                // title={<Alert severity="info">Only Live General foremens are showing below!</Alert>}
                                                columns={[
                                                    { title: 'Employee No', field: 'emp_no', },
                                                    { title: 'First Name', field: 'first_name', },
                                                    { title: 'Last Name', field: 'last_name', },
                                                    { title: 'Email Address', field: 'email', },
                                                    { title: 'Role', field: 'job_title', lookup: this.props.rolesObj },
                                                    { title: 'Owner Region', field: 'owner_region', lookup: this.props.owner_region },
                                                    { title: 'Owner Delivery Unit', field: 'owner_delivery_unit', lookup: this.props.owner_delivery_unit },
                                                    { title: 'Sector', field: 'sector', lookup: this.props.sector },
                                                    { title: 'Start Date', field: 'start_date', type: 'date' },
                                                    { title: 'End Date', field: 'end_date', type: 'date' },
                                                ]}
                                                data={this.state.unAllocatedGF}
                                                isLoading={this.state.isLoading}
                                                options={{
                                                    headerStyle: {
                                                        fontWeight: 'bold',
                                                    },
                                                    padding: 'dense',
                                                    // showTitle: false,
                                                    // searchFieldAlignment: 'left',
                                                    paging: false,
                                                    pageSize: 20
                                                }}
                                                onRowClick={(event, rowData, togglePanel) => {
                                                    this.replaceOps(rowData)
                                                }}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </Paper>
                            </Box>
                            <Dialog open={this.state.replaceLoading} maxWidth={"md"}>
                                <DialogContent style={{ padding: "70px" }}>
                                    <CoffeeLoading />
                                </DialogContent>
                            </Dialog>
                            <Dialog open={this.state.excelExportDialog} onClose={() => this.setState({ excelExportDialog: false })} >
                                <DialogContentText>
                                    <Box margin={2}>
                                        The Report will be emailed to {user.email}
                                    </Box>
                                </DialogContentText>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ excelExportDialog: false })} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                }
            </>
        );
    }
}

export default withSnackbar(withStyles(useStyle)(OHLStaff));