// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { getClientID } from './utils/reuseFunctions';
 
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: getClientID(),
    redirectUri: "https://"+ window.location.hostname +"/",
    postLogoutRedirectUri: "https://" + window.location.hostname + "/",
    validateAuthority: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    "openid",
    "email",
    "offline_access"
  ]
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  // tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)