import React from 'react';
import { Route } from 'react-router-dom';
import Auth from '../utils/authenticate';
import { getPrimaryColor } from '../components/Helper/CommonThings';
import { LadderLoading } from 'react-loadingg'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        Auth.isAuthenticated()  && Auth.user()
            ? <Component {...props} />
            : <LadderLoading color={getPrimaryColor()} />
    )} />
)

export default PrivateRoute;